import React from 'react';
// TODO: use LocalizedLink
import { Link } from 'gatsby';

const Home = () => (
  <div>
    <h1>Authenticated User - Home</h1>
    <Link to="/app/profile">Profile Details</Link>
  </div>
);

export default Home;
