import React from 'react';
// import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import Layout from '../components/layouts/Layout';
import Details from '../components/screens/Details';
import Home from '../components/screens/Home';
import Login from '../components/screens/Login';
import SignUp from '../components/screens/SignUp';
import PrivateRoute from '../components/utils/PrivateRoute';

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/home" component={Home} />
      <PrivateRoute path="/app/profile" component={Details} />
      <Login path="/app/login" />
      <SignUp path="/app/signup" />
    </Router>
  </Layout>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
