import styled from 'styled-components';

import variables from '../../../styles/variables';

const Button = styled.button`
  background-color: ${variables.primaryColor};
  padding: 15px 7px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  color: white;
  border: none;
  ${props => (props.disabled ? 'background-color: #ccc; pointer-events: none;' : null)}
`;

export default Button;
