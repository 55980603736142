import React from 'react';
import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

// utils
import { logIn, isLoggedIn } from '../../utils/auth';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Form from '../elements/Form';

class Login extends React.Component {
  state = {
    username: '',
    password: '',
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  login = async () => {
    const { username, password } = this.state;
    const { user, error } = await logIn(username, password);
    if (user) {
      navigate('/app/home');
    } else {
      // TODO: we should probably change this to an inline message;
      alert(error.message); // eslint-disable-line no-alert
    }
  };

  render() {
    if (isLoggedIn()) navigate('/app/profile');
    const { username, password } = this.state;
    return (
      <div>
        <h1>Login</h1>
        <Form>
          <Input
            onChange={this.handleUpdate}
            placeholder="Username"
            name="username"
            value={username}
          />
          <Input
            onChange={this.handleUpdate}
            placeholder="Password"
            name="password"
            value={password}
            type="password"
          />
          <Button type="button" onClick={this.login}>
            Login
          </Button>
        </Form>
        <Link to="/app/signup">Sign Up</Link>
        <br />
      </div>
    );
  }
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
