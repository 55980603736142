import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
// TODO: remove Auth, add utils/auth
import { Auth } from 'aws-amplify';

import Input from '../elements/Input';
import Button from '../elements/Button';
import Form from '../elements/Form';

const initialState = {
  username: '',
  password: '',
  email: '',
  phoneNumber: '',
  authCode: '',
  stage: 0,
};

class SignUp extends React.Component {
  state = initialState;

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  signUp = async () => {
    const { username, password, email, phoneNumber } = this.state;
    try {
      // TODO: don't call Auth methods here. Move this to a register function in src/utils/auth.js
      await Auth.signUp({
        username,
        password,
        attributes: { email, phone_number: phoneNumber },
      });
      this.setState({ stage: 1 });
    } catch (err) {
      console.error('error signing up...', err);
    }
  };

  confirmSignUp = async () => {
    const { username, authCode } = this.state;
    try {
      await Auth.confirmSignUp(username, authCode);
      alert('Successfully signed up!'); // eslint-disable-line
      navigate('/app/login');
    } catch (err) {
      console.error('error confirming signing up...', err);
    }
  };

  render() {
    const { email, phoneNumber, stage, username, password, authCode } = this.state;
    return (
      <div>
        <h1>Sign Up</h1>
        {stage === 0 && (
          <Form>
            <Input
              onChange={this.handleUpdate}
              placeholder="Username"
              name="username"
              value={username}
            />
            <Input
              onChange={this.handleUpdate}
              placeholder="Password"
              name="password"
              value={password}
              type="password"
            />
            <Input onChange={this.handleUpdate} placeholder="Email" name="email" value={email} />
            <Input
              onChange={this.handleUpdate}
              placeholder="Phone Number"
              name="phoneNumber"
              value={phoneNumber}
            />
            <Button type="button" onClick={this.signUp}>
              Sign Up
            </Button>
          </Form>
        )}
        {stage === 1 && (
          <Form>
            <Input
              onChange={this.handleUpdate}
              placeholder="Authorization Code"
              name="authCode"
              value={authCode}
            />
            <Button type="button" onClick={this.confirmSignUp}>
              Confirm Sign Up
            </Button>
          </Form>
        )}
        <Link to="/app/login">Sign In</Link>
      </div>
    );
  }
}

SignUp.propTypes = {};

SignUp.defaultProps = {};

export default SignUp;
